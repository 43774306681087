import React, {useEffect, useState} from "react";
import algoliasearch from "algoliasearch";
import {
  InstantSearch,
  SearchBox,
  connectHits,
  Index,
  Configure,
  connectInfiniteHits,
  connectSortBy,
  connectStateResults,
  connectRefinementList
} from 'react-instantsearch-dom';
import {
  ProductsList,
  SearchLinkCard,
  ProductListHeader,
  SortBySearch,
  SearchBrandsFilter
} from "benlux-ecommerce-ui";
import withAlgoliaSearch from "../components/withAlgoliaSearch";
import {CustomRangeSlider} from "../components/Search";

const searchClient = algoliasearch(
  'JL0T5UHGEA',
  '45d37cd21aa9a1d331afbc94eed3e538'
);

const RefinementList = ({items, refine, searchForItems}) => (
  <SearchBrandsFilter items={items} refine={refine}/>
);

const CustomRefinementList = connectRefinementList(RefinementList);

const SortBy = ({items, refine, createURL}) => (
  <SortBySearch
    href={createURL}
    refine={refine}
    items={items}
  />
);

const CustomSortBy = connectSortBy(SortBy);

const Hits = ({hits, indexName}) => {
  return (
    <div>
      {(indexName.indexOf('products') > 2) ? (
        <div>
          <ProductsList products={hits} searchProps/>
        </div>
      ) : (
        <div>
          <SearchLinkCard hits={hits}/>
        </div>
      )}
    </div>
  )
};

const CustomHits = connectHits(Hits);

const Test = ({hits, hasMore, refineNext, searchResults, searching}) => {
  if (!searchResults) return null;

  return (
    <ProductsList
      products={hits}
      hasMore={hasMore}
      fetchMore={refineNext}
      query={''}
      pagination={{
        currentPage: searchResults.page + 1,
        perPage: searchResults.hitsPerPage,
        totalItems: searchResults.nbHits,
        nextPage: searchResults.nbPages > (searchResults.page + 1) ? searchResults.page + 2 : null
      }}
      loading={searching}
      searchProps
    />
  );
}

const CustomTest = connectStateResults(Test);

const InfiniteProductHits = ({hits, refineNext, hasMore, searchState, indexNamePrefix, ...data}) => {
  return (
    <>
      <ProductListHeader
        searchQuery={searchState}
      >
        <CustomRangeSlider attribute={"price.EUR.default"}/>
        <CustomRefinementList attribute="manufacturer" limit={20}/>
        <CustomSortBy
          defaultRefinement={indexNamePrefix + 'default_products'}
          items={[
            {value: indexNamePrefix + 'default_products', label: 'Normal'},

            {
              value: indexNamePrefix + 'default_products_price_asc',
              label: 'Prix croissant'
            },
            {
              value: indexNamePrefix + 'default_products_price_desc',
              label: 'Prix décroissant'
            },
            {
              value: indexNamePrefix + 'default_products_created_at',
              label: 'Nouveautés'
            },
          ]}
        />
      </ProductListHeader>
      <CustomTest
        hits={hits}
        hasMore={hasMore}
        refineNext={refineNext}
      />
    </>
  )
}

const ConnectedInfiniteProductHits = connectInfiniteHits(InfiniteProductHits);

const SearchPage = withAlgoliaSearch(({hits, currentRefinement, refine, match, algoliaSearchConfig}) => {
  const {indexNamePrefix} = algoliaSearchConfig;

  const indices = [
    indexNamePrefix + 'default_categories',
    indexNamePrefix + 'default_pages',
    indexNamePrefix + 'default_products',
  ];
  const [searchState, setSearchState] = useState({query: match.params.value});

  useEffect(() => {
    const updatedSearchState = {...searchState, query: match.params.value};
    if (updatedSearchState.indices && updatedSearchState.indices[indices[2]]) {
      updatedSearchState.indices[indices[2]].page = 2;
    }

    setSearchState(updatedSearchState);
  }, [match]);

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={indices[0]}
      searchState={searchState}
      onSearchStateChange={(searchState) => {
        setSearchState(searchState)
      }}
    >
      <div style={{display: 'none'}}>
        <SearchBox/>
      </div>
      {indices.map((index) => (
        <Index indexName={index}>
          {index !== indices[2] ? (
            <>
              <Configure hitsPerPage={6}/>
              <CustomHits indexName={index}/>
              {index === indexNamePrefix + 'default_categories' && (
                <Configure filters="include_in_menu=1"/>
              )}
            </>
          ) : (
            <>
              <Configure hitsPerPage={32}/>
              <ConnectedInfiniteProductHits searchState={searchState} indexNamePrefix={indexNamePrefix}/>
            </>
          )}
        </Index>
      ))}
    </InstantSearch>
  )
});


export default SearchPage;
